/**
* Created by hansxing on 2018/11/27.
*/
<style lang="scss" scoped>
.invoice-list {

    .invoice-list_sect {
        background: #fff;
        margin-top: 10px;
        padding: 10px;
    }

    .list_sect_info {
        display: flex;

        li {
            margin-right: 10px;
            font-size: 12px;
            background: #F0F8FF;
            border-radius: 4px;
            padding: 10px;
            line-height: 18px;

            &:last-child {
                margin-right: 0;
            }
        }

        .sect_info_weight {
            font-weight: 600;
        }

        .sect_info_blue {
            color: #1989FA;
        }

        .sect_info_red {
            color: #FF3B30;
        }

        .sect_info_blue_line {
            /*text-decoration: underline;*/
        }

        .sect_info_price {
            font-size: 16px;
            margin-top: 10px;
        }

        .sect_info_rise {
            margin-top: 20px;
        }

        .sect_info_one {
            width: 260px;
        }

        .sect_info_two {
            width: 300px;
        }

        .sect_info_three {
            width: 540px;
        }

        .sect_info_four {
            /*min-width: 220px;*/
            min-width: 460px;
            width: calc(100% - 650px);

        }

    }

    .list_sect_btn {
        text-align: right;
        margin-top: 10px;

        .el-button {
            position: relative;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-width: 8px 8px 0 8px;
                border-style: solid;
                border-color: #e6a23c transparent transparent transparent;
                position: absolute;
                bottom: -8px;
                left: 50%;
                margin-left: -8px;
                opacity: 0;
            }

        }
    }

    .sect_btn_select {
        background: #e6a23c;
        border-color: #e6a23c;
        color: #fff;

        &.el-button {
            &:before {
                opacity: 1;
            }
        }
    }

    .invoice-express_modify {
        background: #F0F8FF;
        border: 1px solid #87C3FF;
        padding: 10px;
        margin-top: 10px;

        .el-form-item {
            //margin-bottom: 0;
        }
    }

    .express_modify_btn {
        text-align: right;
        font-size: 14px;
        color: #909399;
    }

    .express_modify_btn_o {
        width: 100%;
        text-align: right;
        margin-top: 15px;
    }

}

.com-eTrip-footer {
    .invoice-list_paging {
        padding-right: 0;
        text-align: right;
        background: #fff;
        display: flex;
        align-items: center;

        .invoice-list_paging_l {
            display: flex;
            align-items: center;
        }
    }
}

.invoice-list::v-deep {
    .common-list-search .list-search-before .el-form-item.el-form-item--small {
        //margin-bottom: 0;
        height: 32px
    }
}
</style>
<style lang="scss">
.invoice-list {
    .common-list-search {
        .list-search-before {
            width: calc(100% - 350px);
        }

        .list-search-after {
            width: 350px;
            text-align: right;
        }
    }
}
</style>
<template>
    <div>
        <el-main class="com-eTrip-section" :class="{'section-has-footer':invoiceListInfo && invoiceListInfo.length>0}"
                 v-loading="loading">
            <div class="eTrip-section-cont invoice-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" size="small">
                            <el-form-item style="width: auto">
                                <el-radio-group v-model="formInvoice.statusValue">
                                    <el-radio-button label="">
                                        全部
                                    </el-radio-button>
                                    <el-radio-button :label="list.value" v-for="list in invoiceStatusList"
                                                     :key="list.value">
                                        {{ list.name }}
                                        <span
                                            v-if="list.value == 0 && openTicketNum > 0">{{
                                                openTicketNum
                                            }}</span>
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <!--                            <el-form-item>-->
                            <!--                                <el-select v-model="formInvoice.invoiceModel" placeholder="开票方式"-->
                            <!--                                           @change="selectModel">-->
                            <!--                                    <el-option label="纸质发票" value="0"></el-option>-->
                            <!--                                    <el-option label="电子发票" value="1"></el-option>-->
                            <!--                                    &lt;!&ndash;<el-option&ndash;&gt;-->
                            <!--                                    &lt;!&ndash;v-for="item in invoiceSubjectL"&ndash;&gt;-->
                            <!--                                    &lt;!&ndash;:key="item.value"&ndash;&gt;-->
                            <!--                                    &lt;!&ndash;:label="item.name"&ndash;&gt;-->
                            <!--                                    &lt;!&ndash;:value="item.value">&ndash;&gt;-->
                            <!--                                    &lt;!&ndash;</el-option>&ndash;&gt;-->
                            <!--                                </el-select>-->
                            <!--                            </el-form-item>-->
                            <el-form-item>
                                <ApiSelect :options="invoiceSourceType" placeholder="开票渠道"
                                           v-model="formInvoice.invoiceSourceType" clearable></ApiSelect>
                            </el-form-item>
                            <el-form-item>
                                <ApiSelect :options="createType" v-model="formInvoice.createType"
                                           placeholder="开票方式"></ApiSelect>
                            </el-form-item>
                            <el-form-item>
                                <el-select v-model="formInvoice.invoiceType" placeholder="开票类型">
                                    <!--<el-option label="开票类型" value=""></el-option>-->
                                    <el-option
                                        v-for="item in invoiceTypeL"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-select v-model="formInvoice.subject" placeholder="开票主体">
                                    <!--<el-option label="开票主体" value=""></el-option>-->
                                    <el-option
                                        v-for="item in invoiceSubjectL"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="formInvoice.orderNum" placeholder="订单编号"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="formInvoice.invoiceCode" placeholder="发票代码"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="formInvoice.invoiceNum" placeholder="发票号码"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="formInvoice.expressNum" placeholder="快递单号"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-date-picker
                                    v-model="formInvoice.dates"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="list-search-after">
                        <el-button @click="invoiceScrResetClick()">重置</el-button>
                        <el-button type="primary" @click="getInvoiceList(1)">筛选</el-button>
                        <el-button type="primary" @click="handleInvoiceExport()">发票导出</el-button>
                        <el-button type="primary" v-if="isCanCreate" @click="handleAdd">开票</el-button>
                    </div>
                </com-list-search>
                <div class="invoice-list_sect" v-for="item in invoiceListInfo" :key="item.id">
                    <ul class="list_sect_info">
                        <li class="sect_info_one">
                            <p>
                                <span class="sect_info_weight">订单编号：</span>
                                <span class="sect_info_blue sect_info_blue_line">{{ item.orderNo }}</span>
                            </p>
                            <p>
                                <span class="sect_info_weight">开票渠道：</span>
                                <span class="sect_info_blue sect_info_blue_line">{{
                                        item.invoiceSourceType | formatDesc(invoiceSourceType)
                                    }}</span>
                            </p>
                            <p>
                                <span class="sect_info_weight">开票状态：</span>
                                <span class="sect_info_blue" v-if="item.status == 0">待开票</span>
                                <span class="sect_info_blue" v-if="item.status == 1">已开票</span>
                                <span class="sect_info_blue" v-if="item.status == 2">开票中</span>
                                <span class="sect_info_red" v-if="[3,5].includes(item.status )">开票失败</span>
                                <span style="color: #999" v-if="item.status == 4">已冲红</span>
                                <el-button type="primary"
                                           v-if="item.status == 3"
                                           style="margin-left: 20px;"
                                           size="mini"
                                           @click="handleRefreshStatus(item.orderId)">刷新
                                    @click="handleRefreshStatus(item.orderId)">刷新
                                </el-button>
                            </p>
                            <p>
                                <span class="sect_info_weight">申请时间：</span>
                                <span>{{ item.createTime | dateCus }}</span>
                            </p>
                            <p v-if="item.status == 3&&item.invoiceModel == '1'">
                                <span class="sect_info_weight">结果信息：</span>
                                <span>{{ item.invoiceFailReason }}</span>
                            </p>
                            <p class="sect_info_price sect_info_weight">
                                <span>开票金额：</span>
                                <span class="sect_info_red">￥{{ item.invoiceAmount }}</span>
                            </p>
                        </li>
                        <li class="sect_info_two">
                            <p>
                                <span class="sect_info_weight">开票主体：</span>
                                <span>{{ item.invoiceSubject | formatDesc(invoiceSubjectL,'value','name') }}</span>
                            </p>
                            <!--                            <p>-->
                            <!--                                <span class="sect_info_weight">开票方式：</span>-->
                            <!--                                &lt;!&ndash;<span v-if="item.invoiceType == '1'">个人</span>&ndash;&gt;-->
                            <!--                                <span v-if="item.invoiceModel == '1'">电子发票</span>-->
                            <!--                                <span v-if="item.invoiceModel == '0'">纸质发票</span>-->
                            <!--                            </p>-->
                            <p>
                                <span class="sect_info_weight">开票类型：</span>
                                <span v-if="item.invoiceType == '1'">个人</span>
                                <span v-if="item.invoiceType == '2'">公司普通</span>
                                <span v-if="item.invoiceType == '3'">公司专用</span>
                            </p>
                            <p>
                                <span class="sect_info_weight">开票方式：</span>
                                <span>{{ item.createType | formatDesc(createType) }}</span>
                            </p>
                            <p class="sect_info_rise">
                                <span class="sect_info_weight">开票内容：</span>
                                <span>{{ item.invoiceContent }}</span>
                            </p>
                            <p v-if="item.invoiceHead">
                                <span class="sect_info_weight">抬头：</span>
                                <span>{{ item.invoiceHead }}</span>
                            </p>
                            <p v-if="item.invoiceTax">
                                <span class="sect_info_weight">税号：</span>
                                <span>{{ item.invoiceTax }}</span>
                            </p>
                            <p v-if="item.companyAddress">
                                <span class="sect_info_weight">公司地址：</span>
                                <span>{{ item.companyAddress }}</span>
                            </p>
                            <p v-if="item.companyPhone">
                                <span class="sect_info_weight">公司电话：</span>
                                <span>{{ item.companyPhone }}</span>
                            </p>
                            <p v-if="item.bankName">
                                <span class="sect_info_weight">银行名称：</span>
                                <span>{{ item.bankName }}</span>
                            </p>
                            <p v-if="item.bankAccount">
                                <span class="sect_info_weight">银行账号：</span>
                                <span>{{ item.bankAccount }}</span>
                            </p>
                            <p v-if="item.remark||item.groupNo">
                                <span class="sect_info_weight">发票备注：</span>
                                <span>
                                    {{ item.remark || item.groupNo }}
                                </span>
                            </p>
                        </li>
                        <li class="sect_info_four" v-if="item.invoiceModel=='0'">
                            <p class="sect_info_weight">邮寄地址：</p>
                            <p v-if="item.recipetName">{{ item.recipetName }}，{{ item.mobile }}</p>
                            <p>{{ item.provinceName }}{{ item.cityName }}{{ item.address }}</p>
                            <p>{{ item.zipCode }}</p>
                            <p class="sect_info_rise"><span
                                class="sect_info_weight">发票号码：</span><span>{{ item.invoiceNumber }}</span>
                            </p>
                            <p><span
                                class="sect_info_weight">快递公司：</span><span>{{
                                    item.expressCompany
                                }}</span>
                            </p>
                            <p><span
                                class="sect_info_weight">快递单号：</span><span>{{ item.expressNumber }}</span>
                            </p>
                        </li>
                        <li class="sect_info_four" v-if="item.invoiceModel=='1'">
                            <p><span class="sect_info_weight">推送手机：</span>
                                <span>
                                    {{ item.recipetName ? `${item.recipetName}，` : `` }}{{ item.mobile }}
                                </span>
                            </p>
                            <p><span class="sect_info_weight">推送邮箱：</span><span>{{ item.email }}</span></p>

                            <div>
                                <p class="sect_info_rise"><span
                                    class="sect_info_weight">发票流水号：</span><span>{{ item.invoiceSerialNum }}</span>
                                </p>
                                <p><span
                                    class="sect_info_weight">开票时间：</span><span>{{
                                        item.createTime | dateCus('yyyy-MM-dd hh:mm:ss')
                                    }}</span>
                                </p>
                                <p><span class="sect_info_weight">发票代码：</span><span>{{ item.invoiceCode }}</span>
                                </p>
                                <p><span class="sect_info_weight">发票号码：</span><span>{{ item.invoiceNumber }}</span>
                                </p>
                                <p><span
                                    class="sect_info_weight">不含税金额：</span><span>{{
                                        item.hjje || item.taxExcludedAmount
                                    }}</span>
                                </p>
                                <p><span
                                    class="sect_info_weight">合计税额：</span><span>{{
                                        item.hjse || item.taxIncludedAmount
                                    }}</span>
                                </p>
                                <p><span class="sect_info_weight">发票类型：</span><span>{{ item.invoiceLine }}</span>
                                </p>
                                <p><span class="sect_info_weight">发票PDF：</span>
                                    <span v-if="item.invoiceFileUrl" class="sect_info_blue sect_info_blue_line"
                                          style="cursor: pointer"
                                          @click="openPDF(item.invoiceFileUrl)">查看</span>
                                </p>
                            </div>
                        </li>
                    </ul>
                    <div class="list_sect_btn" v-if="false">
                        <el-button type="warning"
                                   :class="{'sect_btn_select': modifyType == 3 && modifyShow && showOrderId == item.orderId}"
                                   v-if="item.status == 1&&item.invoiceModel=='0'"
                                   @click="invoiceExpressBtn(3, item)" plain>编辑快递信息
                        </el-button>
                        <el-button type="warning"
                                   :class="{'sect_btn_select': modifyType == 2 && modifyShow && showOrderId == item.orderId}"
                                   v-if="item.status == 1&&(item.invoiceModel=='0'||invoiceListType)"
                                   @click="invoiceExpressBtn(2, item)" plain>编辑开票信息
                        </el-button>
                        <el-button type="warning"
                                   :class="{'sect_btn_select': modifyType == 1 && modifyShow && showOrderId == item.orderId}"
                                   v-if="item.status == 0&&item.invoiceModel=='0'"
                                   @click="invoiceExpressBtn(1, item)" plain>开票
                        </el-button>
                        <el-button type="warning"
                                   v-if="item.status == 0&&item.invoiceModel=='1'&&item.invoiceSubject==='1'"
                                   @click="electInvoiceBtn(item)" plain>开票
                        </el-button>
                        <el-button type="warning" v-if="item.status===0&&item.invoiceSubject==='2'"
                                   @click="handleCompleteInvoicing(item)">开票
                        </el-button>
                        <el-button type="warning" v-if="item.status===1&&item.invoiceSourceType===8&&isCanCreate"
                                   @click="handleCreateRedInvoice(item)">冲红
                        </el-button>
                    </div>
                    <div class="invoice-express_modify" v-if="modifyShow && showOrderId == item.orderId">
                        <el-form :inline="true">
                            <el-form-item label="发票编号：" v-if="modifyType == 1 || modifyType == 2">
                                <el-input v-model="formInvoiceExpress.invoiceNumber"
                                          placeholder="请输入发票编号（选填）"></el-input>
                            </el-form-item>
                            <el-form-item label="快递公司：" v-if="modifyType == 1 || modifyType == 3">
                                <el-select v-model="formInvoiceExpress.expressType">
                                    <el-option v-for="idx in expressTypeL"
                                               :key="idx.value"
                                               :label="idx.name"
                                               :value="idx.name"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="快递单号：" v-if="modifyType == 1 || modifyType == 3">
                                <el-input v-model="formInvoiceExpress.expressNumber"
                                          placeholder="请输入快递单号（选填）"></el-input>
                            </el-form-item>
                            <el-form-item :class="{'express_modify_btn_o':modifyType == 1}">
                                <div class="express_modify_btn">
                                    <span v-if="modifyType == 1"
                                          style="margin-right: 20px">开票后发票内容将不可更改，确认继续？</span>
                                    <el-button @click="cancelConfirm">取消</el-button>
                                    <el-button type="primary" @click="upConfirmInvoice(item)" :disabled="isBtnDis">确认
                                    </el-button>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <default-empty :text="'暂无订单数据'"
                               v-if="!invoiceListInfo || invoiceListInfo.length==0"></default-empty>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px" v-if="invoiceListInfo && invoiceListInfo.length>0">
            <div class="eTrip-footer-cont invoice-list_paging">
                <div class="invoice-list_paging_l">
                    <div>
                        <p>
                            <span class="color-gary">本月发票额度：</span>
                            <countTo :startVal='0' :endVal='invoiceBalance.bysxed||0' :duration='1000'
                                     separator=""></countTo>
                        </p>
                        <p>
                            <span class="color-gary">可用剩余额度：</span>
                            <countTo :startVal='0' :endVal='invoiceBalance.kysyed||0'
                                     :duration='1000' separator=""></countTo>

                        </p>
                    </div>
                    <div class="ml20">
                        <p><span class="color-gary">已下载额度：</span>
                            <countTo :startVal='0' :endVal='invoiceBalance.yxzed||0'
                                     :duration='1000' separator=""></countTo>
                        </p>
                        <p><span class="color-gary">已下载未使用额度：</span>
                            <countTo :startVal='0' :endVal='invoiceBalance.yxzwsyed||0'
                                     :duration='1000' separator=""></countTo>
                        </p>
                    </div>
                    <el-button type="primary" plain :loading="invoiceBalanceLoading" size="small" class="ml20"
                               @click="getInvoiceBalance">
                        刷新
                    </el-button>
                </div>
                <div class="block" style="flex: 1">
                    <el-pagination
                        @current-change="invListPageChange"
                        :current-page.sync="invoicePage.currentPage"
                        :page-size="invoicePage.pageSize"
                        layout="total, prev, pager, next"
                        :total="invoicePage.totalPage">
                    </el-pagination>
                </div>

                <!--@size-change="handleSizeChange"-->
                <!--:current-page.sync="bEndListPage.currentPage"-->
            </div>
        </el-footer>
        <add-invoice ref="addInvoiceRef" @onRefresh="onRefresh"></add-invoice>
    </div>
</template>

<script type="text/ecmascript-6">
import comListSearch from '../../../components/comListSearch/index.vue'
import defaultEmpty from '../../../components/bmsempty/index.vue'
import dayjs from "dayjs";
import AddInvoice from './AddInvoice.vue'
import {createRedInvoiceApi} from '@/www/urls/orderUrls'
import countTo from 'vue-count-to';

const urls = {
    list: {
        0: '/galaxyInvoiceApi/doOrderInvoiceList',
        1: '/galaxyInvoiceApi/getOrderZslInvoiceList'
    },
    count: {
        0: '/galaxyInvoiceApi/countBMS/orderInvoice',
        1: '/galaxyInvoiceApi/getCountZslOrderInvoice'
    },
    exportList: {
        0: '/galaxyInvoiceApi/exportOrderInvoiceList',
        1: '/galaxyInvoiceApi/exportZslOrderInvoiceList'
    }
}

export default {
    //定义模版数据
    data() {
        return {
            loading: false,
            formInvoice: {
                statusValue: '',  // 开票状态
                subject: '',  // 开票主体
                invoiceType: ''  // 发票类型
            },
            invoiceStatusList: [
                {name: '待开票', value: '0'},
                {name: '已开票', value: '1'},
                {name: '开票中', value: '2'},
                // {name: '开票中', value: '3'},
                {name: '已冲红', value: '4'},
                {name: '开票失败', value: '5'},
            ],
            invoiceSubjectL: [
                {name: '上海久柏易游信息科技有限公司', value: '1'},
                {name: '上海久柏易游国际旅行社有限公司', value: '2'},
                {name: '上海久柏易游信息科技有限公司杭州分公司', value: '3'},
            ],
            invoiceSourceType: [
                {
                    label: 'TDS',
                    value: 1
                },
                {
                    label: '高德',
                    value: 2
                },
                {
                    label: '飞猪',
                    value: 3
                },
                {
                    label: '携程',
                    value: 4
                },
                {
                    label: '同程',
                    value: 5
                },
                {
                    label: '航旅',
                    value: 6
                },
                {
                    label: '南航',
                    value: 7
                },
                {
                    label: 'BMS',
                    value: 8
                }
            ],
            createType: [
                {
                    label: '税盘',
                    value: '0'
                },
                {
                    label: '乐企',
                    value: '1'
                }
            ],
            invoiceTypeL: [
                {name: '个人', value: '1'},
                {name: '公司普通', value: '2'},
                {name: '公司专用', value: '3'}
            ],
            expressTypeL: [
                {name: '邮政快递', value: '1'}
            ],
            formInvoiceExpress: {
                expressType: '邮政快递'
            },
            modifyShow: false,
            showOrderId: '',
            modifyType: '',
            invoicePage: {
                pageIndex: 1,
                pageSize: 10,
                totalPage: 1,
            },
            openTicketNum: '',  //  待开票数量
            isBtnDis: true,  // 开票默认禁止
            invoiceListInfo: {},
            invoiceBalanceLoading: false,
            invoiceBalance: {}
        }
    },
    components: {comListSearch, defaultEmpty, AddInvoice, countTo},
    //计算属性
    computed: {
        invoiceListType() {
            //用车发票和中山陵发票 接口分开
            return this.$route.name === 'zslInvoiceList' ? 1 : 0
        },
        isCanCreate() {
            return ['总部财务', "总部超级管理员"].includes(this.$store.state.loginUserInfo.roleName)
        }
    },
    //主件被加载完成
    mounted() {
        this.getInvoiceList(1);
        this.getCountOrderInvoice();
        this.getInvoiceBalance();
    },
    //定义事件方法
    methods: {
        invoiceScrResetClick() {
            this.formInvoice = {
                statusValue: '',
                subject: '',
                invoiceType: '',
                orderNum: '',
                invoiceNum: '',
                expressNum: '',
            };
            this.getInvoiceList(1)
        },
        invListPageChange(val) {
            this.getInvoiceList(val)
        },
        invoiceExpressBtn(type, item) {
            this.showOrderId = item.orderId;
            this.modifyShow = true;
            this.modifyType = type;
        },
        electInvoiceBtn(item) {
            this.$confirm('开票后内容将不可更改,确认继续?', '开票', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                this.loadingShow();
                let form = {
                    orderId: item.orderId,
                    invoiceModel: item.invoiceModel,
                    invoiceSourceType: item.invoiceSourceType,
                    orderInvoiceStatus: 1,
                };
                let res = await this.http('/galaxyInvoiceApi/updateBMS/orderInvoice', form, 'POST');
                if (res.success) {
                    this.$message.success('开票成功')
                    this.getInvoiceList(this.invoicePage.currentPage);
                } else {
                    this.$message.error(res.errors[0].message);
                }
                this.loadingHide();
            }).catch(() => {
                this.loadingHide();
            });
        },
        async getInvoiceList(page) {
            this.loadingShow();
            this.invoicePage.pageIndex = page;
            let form = {
                pageIndex: page,
                pageSize: this.invoicePage.pageSize
            };

            if (this.formInvoice.statusValue) form.orderInvoiceStatus = this.formInvoice.statusValue;  // 开票状态
            if (this.formInvoice.subject) form.invoiceSubject = this.formInvoice.subject;  // 开票主体
            if (this.formInvoice.invoiceType) form.orderInvoiceType = this.formInvoice.invoiceType;  // 发票类型
            if (this.formInvoice.orderNum) form.orderNo = this.formInvoice.orderNum;  // 订单编号
            if (this.formInvoice.invoiceNum) form.invoiceNumber = this.formInvoice.invoiceNum;  // 发票编号
            if (this.formInvoice.expressNum) form.expressNumber = this.formInvoice.expressNum;  // 快递单号
            if (this.formInvoice.invoiceModel) form.invoiceModel = this.formInvoice.invoiceModel;
            if (this.formInvoice.invoiceCode) form.invoiceCode = this.formInvoice.invoiceCode;
            if (this.formInvoice.invoiceSourceType) form.invoiceSourceType = this.formInvoice.invoiceSourceType;
            if (this.formInvoice.createType) form.createType = this.formInvoice.createType;
            if (this.formInvoice.dates && this.formInvoice.dates.length) {
                form.startCreateTime = dayjs(this.formInvoice.dates[0]).startOf('d').valueOf()
                form.endCreateTime = dayjs(this.formInvoice.dates[1]).endOf('d').valueOf()
            }
            let res = await this.http(urls.list[this.invoiceListType], form, 'POST');
            if (res.success) {
                this.invoiceListInfo = res.data.data;
                this.invoicePage.totalPage = res.data.total;
            }
            this.loadingHide();
        },
        async getCountOrderInvoice() {
            let res = await this.http(urls.count[this.invoiceListType], {}, 'POST');
            if (res.success) {
                this.openTicketNum = res.data.invoiceNotIssueCount
            }
        },
        //直接完成开票
        async handleCompleteInvoicing(item) {
            try {
                await this.$confirm('确定开票？', '提示')
                this.loadingShow()
                let res = await this.http('/galaxyOrder/bmsCompleteInvoice', {
                    id: item.id,
                }, 'POST');
                if (res.success) {
                    this.$message.success('提交成功！')
                    this.getInvoiceList(this.invoicePage.pageIndex)
                } else this.$message.error(res.errors[0].message)
            } finally {
                this.loadingHide()
            }
        },
        async upConfirmInvoice(item) {
            let form = {
                orderId: item.orderId,
            }
            if (this.formInvoiceExpress.invoiceNumber) form.invoiceNumber = this.formInvoiceExpress.invoiceNumber; //  发票编号
            if (this.formInvoiceExpress.expressType) form.expressCompany = this.formInvoiceExpress.expressType; // 快递公司
            if (this.formInvoiceExpress.expressNumber) form.expressNumber = this.formInvoiceExpress.expressNumber; // 快递单号
            if (item.status == 0) form.orderInvoiceStatus = 1;

            let res = await this.http('/galaxyInvoiceApi/updateBMS/orderInvoice', form, 'POST');

            if (res.success) {
                if (res.data) {
                    let successTitle = '';
                    if (this.modifyType == 1) {
                        successTitle = '开票成功'
                    } else {
                        successTitle = '编辑成功'
                    }
                    this.$message({
                        message: successTitle,
                        type: 'success'
                    });
                }
                this.cancelConfirm();
                this.getInvoiceList(1);
                this.getCountOrderInvoice;
            }
        },
        cancelConfirm() {
            this.modifyShow = false;
            this.formInvoiceExpress = {
                invoiceNumber: '',
                expressType: '邮政快递',
                expressNumber: ''

            }
        },
        openPDF(url) {
            window.open(url)
        },
        selectModel(val) {
            let invoiceTypeL = [
                {name: '个人', value: '1'},
                {name: '公司普通', value: '2'}
            ];
            if (val == 0) {
                invoiceTypeL.push({name: '公司专用', value: '3'});
            }
            this.invoiceTypeL = invoiceTypeL;
        },
        //刷新发票状态
        async handleRefreshStatus(orderId) {
            this.loadingShow();
            const ret = await this.http('/galaxyInvoiceApi/updateOrderInvoiceStatus', {orderId}, "POST");
            if (ret.success && ret.data) {
                await this.getInvoiceList(this.invoicePage.pageIndex);
            }
            this.loadingHide();
        },
        async handleInvoiceExport() {
            this.loadingShow();
            let form = {};
            if (this.formInvoice.statusValue) form.orderInvoiceStatus = this.formInvoice.statusValue;  // 开票状态
            if (this.formInvoice.subject) form.invoiceSubject = this.formInvoice.subject;  // 开票主体
            if (this.formInvoice.invoiceType) form.orderInvoiceType = this.formInvoice.invoiceType;  // 发票类型
            if (this.formInvoice.orderNum) form.orderNo = this.formInvoice.orderNum;  // 订单编号
            if (this.formInvoice.invoiceNum) form.invoiceNumber = this.formInvoice.invoiceNum;  // 发票编号
            if (this.formInvoice.expressNum) form.expressNumber = this.formInvoice.expressNum;  // 快递单号
            if (this.formInvoice.invoiceModel) form.invoiceModel = this.formInvoice.invoiceModel;
            if (this.formInvoice.invoiceCode) form.invoiceCode = this.formInvoice.invoiceCode;
            if (this.formInvoice.invoiceSourceType) form.invoiceSourceType = this.formInvoice.invoiceSourceType;
            if (this.formInvoice.createType) form.createType = this.formInvoice.createType;
            if (this.formInvoice.dates && this.formInvoice.dates.length) {
                form.startCreateTime = dayjs(this.formInvoice.dates[0]).startOf('d').valueOf()
                form.endCreateTime = dayjs(this.formInvoice.dates[1]).endOf('d').valueOf()
            }
            try {
                let res = await this.http(urls.exportList[this.invoiceListType], form, 'POST', true, 'blob');
                let d = new Date().getTime();
                let a = document.createElement('a');
                a.download = '发票列表导出' + d + '.xls';
                a.href = window.URL.createObjectURL(res);
                a.click();

                // }
                this.loadingHide();
            } catch (e) {
                this.loadingHide();
                this.messageError('下载失败')
            }
        },  // 发票导出
        handleAdd() {
            this.$refs['addInvoiceRef'].open();
        },
        onRefresh() {
            this.getInvoiceList(1)
        },
        //红冲
        async handleCreateRedInvoice(item) {
            try {
                await this.$confirm('确认红冲？', '提示')
                this.loading = true
                const ret = await createRedInvoiceApi({
                    invoiceId: item.id,   //发票的id
                    invoiceAmount: item.invoiceAmount,//  发票金额
                    orderNo: item.orderNo,//  订单编号
                })
                if (ret.success) {
                    this.$message.success("提交成功！")
                    this.getInvoiceList(this.invoicePage.pageIndex)
                }
            } finally {
                this.loading = false
            }
        },
        //获取总的开票信息
        async getInvoiceBalance() {
            try {
                this.invoiceBalanceLoading = true;
                this.invoiceBalance = {}
                const ret = await this.http('/galaxyInvoiceApi/getInvoiceBalance', {}, 'POST')
                if (ret.success)
                    this.invoiceBalance = JSON.parse(ret.data)
            } finally {
                this.invoiceBalanceLoading = false
            }
        }
    },
    //监听模版变量
    watch: {
        'formInvoiceExpress.invoiceNumber'(n) {
            if (n) this.isBtnDis = false
            else this.isBtnDis = true
        },
        'formInvoiceExpress.expressType'(n) {
            if (n) this.isBtnDis = false
            else this.isBtnDis = true
        },
        'formInvoiceExpress.expressNumber'(n) {
            if (n) this.isBtnDis = false
            else this.isBtnDis = true
        },
        invoiceListType() {
            this.getInvoiceList(1);
            this.getCountOrderInvoice();
        }
    }

}
</script>

